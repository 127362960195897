import React from 'react';
import { Link } from 'gatsby';
import Products from './Products';
import { getProductsFromCategory, getSubCategoriesOfCategory } from '../../utils/helperFunctions';
import { subCategoriesHolder } from './subCategories.module.scss';
import { motion } from 'framer-motion';

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.6,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
  },
};

export const SubCategories = ({ categories, products: allProducts, allCategories }) => {
  return (
    <motion.ul className={subCategoriesHolder} variants={variants}>
      {categories.map((category) => {
        const products = getProductsFromCategory(category.slug, allProducts, true);
        //get subcategories of category
        const subCategories = getSubCategoriesOfCategory(category.slug, allCategories, true);
        return (
          <li key={category.slug}>
            <Link to={`/category/${category.slug}`} title={category.name}>
              <h3>{category.name}</h3>
            </Link>
            <Products products={products} />
            {subCategories.length > 0 && <SubCategories categories={subCategories} products={allProducts} allCategories={allCategories} />}
          </li>
        );
      })}
    </motion.ul>
  );
};

export default SubCategories;
