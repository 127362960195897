import React from 'react';
import Seo from '../components/Seo';
import { fullInventoryHolder } from './fullInventory.module.scss';
import useProducts from '../hooks/useProducts';
import useCategories from '../hooks/useCategories';
import { getSubCategoriesOfCategory } from '../utils/helperFunctions';
import SubCategories from '../components/categoryPages/SubCategories';
import { CameraSvg, LensSvg, MatteboxSvg } from '../images/svgs/productIllustrations';
import { motion } from 'framer-motion';

const holderVariants = {
  initial: {},
  animate: {},
  exit: {},
};

const illustrationVariants = {
  initial: {},
  animate: {
    transition: {
      // staggerChildren: 0.2,
    },
  },
  exit: {},
};

const pageInnerVariants = {
  initial: {},
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.3,
    },
  },
  exit: {},
};

const sectionVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.6,
      duration: 0.6,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    y: 50,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

const cameraVariants = {
  initial: {
    opacity: 0,
    x: '19vw',
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
      type: 'tween',
      ease: 'easeInOut',
      x: {
        duration: 1.5,
        delay: 0.5,
        type: 'tween',
        ease: [0.87, 0, 0.13, 1],
      },
    },
  },
  exit: {
    opacity: 0,
    y: 50,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

const lensVariants = {
  initial: {
    opacity: 0,
    scale: 1.1,
  },
  animate: {
    opacity: 1,
    scale: 1.1,
    transition: {
      duration: 1,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    y: 50,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

const accessoriesVariants = {
  initial: {
    opacity: 0,
    x: '-20vw',
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
      type: 'tween',
      ease: 'easeInOut',
      x: {
        duration: 1.5,
        delay: 0.5,
        type: 'tween',
        ease: [0.87, 0, 0.13, 1],
      },
    },
  },
  exit: {
    opacity: 0,
    y: 50,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

const dashVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 1.5,
      duration: 1,
      type: 'tween',
      ease: 'backInOut',
    },
  },
};

export default function FullInventoryPage() {
  const products = useProducts();
  const categories = useCategories();
  const cameras = getSubCategoriesOfCategory('cameras', categories, true);

  const lenses = getSubCategoriesOfCategory('lenses', categories, true);

  const accessories = getSubCategoriesOfCategory('accessories', categories, true);
  return (
    <motion.article className={fullInventoryHolder} variants={holderVariants} initial="initial" animate="animate" exit="exit">
      <h1>Full Inventory</h1>
      <motion.div className="illustrations" variants={illustrationVariants}>
        <motion.div className="cameraHolder" variants={cameraVariants}>
          <CameraSvg />
        </motion.div>
        <motion.div className="dash1 dashHolder">
          <motion.div variants={dashVariants} className="dashInner">
            <svg width="225" height="2" viewBox="0 0 225 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1L225 0.99998" stroke="#F2F2F2" strokeDasharray="2 2" />
            </svg>
          </motion.div>
        </motion.div>
        <motion.div className="lensHolder" variants={lensVariants}>
          <LensSvg />
        </motion.div>
        <motion.div className="dash2 dashHolder">
          <motion.div variants={dashVariants} className="dashInner">
            <motion.svg width="225" height="2" viewBox="0 0 225 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1L225 0.99998" stroke="#F2F2F2" strokeDasharray="2 2" />
            </motion.svg>
          </motion.div>
        </motion.div>

        <motion.div className="matteboxHolder" variants={accessoriesVariants}>
          <MatteboxSvg />
        </motion.div>
      </motion.div>
      <motion.div className="pageInner" variants={pageInnerVariants}>
        <motion.section variants={sectionVariants}>
          <div className="sectionSvg">
            <CameraSvg />
          </div>
          <h2>Cameras</h2>
          <SubCategories categories={cameras} products={products} allCategories={categories} />
        </motion.section>
        <motion.section variants={sectionVariants}>
          <div className="sectionSvg">
            <LensSvg />
          </div>
          <h2>Lenses</h2>
          <SubCategories categories={lenses} products={products} allCategories={categories} />
        </motion.section>
        <motion.section variants={sectionVariants}>
          <div className="sectionSvg">
            <MatteboxSvg />
          </div>
          <h2>Accessories</h2>
          <SubCategories categories={accessories} products={products} allCategories={categories} />
        </motion.section>
      </motion.div>
    </motion.article>
  );
}

export const Head = () => <Seo title="Full kit list - MCX Films - Boutique moving image equipment rentals based in London" />;
